import * as R from 'ramda'
import {bool, shape, string} from 'prop-types'
import {graphql} from 'gatsby'
import React, {useEffect, useState} from 'react'

import {locationPropTypes, pageContextPropTypes} from 'helpers/propTypes'
import {stringToSlug} from 'helpers/utils'
import ContentSection from 'components/UI/ContentSection'
import HeroDetails from 'components/Services/HeroDetails'
import Section from 'components/UI/Section'
import SEO from 'components/seo'

const DeliveryModels = ({pageContext, data, location}) => {
  const mapIndexed = R.addIndex(R.map)
  const countryData = R.path(['contentfulCountry'], data)
  const [ref, setRef] = useState(false)
  const {hash} = location

  const {pageTitle, contentSections, metaTitle, metaDescription, imageOrVideo} =
    R.pathOr(null, ['contentfulDeliveryModels'], data)
  const {backButton} = R.pathOr(null, ['contentfulServices'], data)

  const shortDescription = R.pathOr(
    ' ',
    ['contentfulDeliveryModels', 'shortDescription', 'shortDescription'],
    data,
  )

  setTimeout(() => {
    const domElement = hash && document.querySelector(hash)

    if (domElement) {
      setRef(domElement)
    }
  }, 100)

  useEffect(
    () => () => {
      if (ref) {
        ref.scrollIntoView({
          alignToTop: false,
          behavior: 'smooth',
          block: 'center',
        })
      }
    },
    [ref],
  )

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <Section hasGreyBackground hasPaddingTop={false} hasPaddingBottom={false}>
        <HeroDetails
          backLink="../../services/"
          backLabel={backButton}
          name={pageTitle}
          description={shortDescription}
          icon={imageOrVideo}
        />
      </Section>
      <Section hasVerySmallPadding>
        {contentSections &&
          mapIndexed(node => {
            const {title} = node
            const slug = stringToSlug(title)

            return (
              <ContentSection
                title={node.title}
                callToAction={node.callToAction}
                variation={node.variation}
                description={node.description}
                image={node.image}
                id={node.id}
                key={node.id}
                countryData={countryData}
                anchor={slug}
              />
            )
          }, contentSections)}
      </Section>
    </>
  )
}

DeliveryModels.propTypes = {
  data: shape({
    contentfulDeliveryModels: shape({
      contentSections: null,
      metaDescription: shape({metaDescription: string}),
      metaTitle: string,
      pageTitle: string,
    }),
    contentfulCountry: shape({
      countryName: string,
      countryCode: string,
      navLocale: string,
      urlPrefix: string,
      isMultilang: bool,
      technicalName: string,
    }),
  }),
  location: locationPropTypes.isRequired,
  pageContext: shape({
    contentId: string,
    lastMod: string,
    nodeLocale: string,
    prefix: string,
    slug: string,
    technicalName: string,
  }),
}

DeliveryModels.defaultProps = {
  data: shape({
    contentSections: null,
    countryData: null,
    metaDescription: shape({metaDescription: ''}),
    metaTitle: '',
    pageTitle: '',
  }),
  pageContext: pageContextPropTypes.isRequired,
}

export default DeliveryModels

export const pageQuery = graphql`
  query templateDeliveryModels(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    contentfulDeliveryModels(
      node_locale: {eq: $nodeLocale}
      slug: {regex: $entityRegEx}
    ) {
      id
      pageTitle
      metaTitle
      shortDescription {
        shortDescription
      }
      metaDescription {
        metaDescription
      }
      imageOrVideo {
        file {
          url
          fileName
          contentType
        }
      }
      contentSections {
        title
        variation
        description {
          raw
        }
        image {
          file {
            url
            fileName
            contentType
          }
        }
      }
    }
    contentfulServices(
      node_locale: {eq: $nodeLocale}
      slug: {regex: "/-kls/"}
    ) {
      backButton
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      countryCode
      isMultilang
      navLocale
      technicalName
      urlPrefix
      ...countryDataFields
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
  }
`
